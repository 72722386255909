import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfig } from 'src/environments/enviroment';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private encryptDecryptService: EncryptDecryptService, private router: Router) { }
  protected apiURL: string = appConfig.apiUrl;
  protected stripeUrl: string = appConfig.stripeUrl;
  ExcludeURLList = [
    this.apiURL + '/auth/generate-patient-portal-token',
  ];
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    debugger;

    if (
      !request.url.startsWith(this.apiURL + '/auth/generate-patient-portal-token') &&
      !request.url.startsWith(this.stripeUrl) &&
      !this.shouldExcludeURL(request.url)
    ) {
      request = this.addJwtToken(request);
      if (request.url.endsWith('/auth/refresh-token')) {
        // If it's the /auth/refresh-token endpoint, exclude payload encryption/decryption but keep headers
        return next.handle(request);
      }
      if (request.method === 'GET') {
        const pathParts = request.url.split('/');
        const lastPartIndex = pathParts.length - 1;
        const encryptedValue = this.encryptDecryptService.encryptUsingAES256(Number(pathParts[lastPartIndex]));
        const newPath = request.url.replace(pathParts[lastPartIndex], encryptedValue);
        request = request.clone({ url: newPath });
      } else if (request.method === 'POST') {
        const encryptedBody = this.encryptDecryptService.encryptUsingAES256(request.body);
        const encryptedReq = request.clone({ body: encryptedBody });
        return next.handle(encryptedReq);
      }
    }

    return next.handle(request);
  }
  jwtToken: any
  private addJwtToken(request: HttpRequest<unknown>) {
    debugger
    const encryptedData = localStorage.getItem('u.');
    if (encryptedData !== null) {
      const decryptedData = this.encryptDecryptService.decryptUsingAES256(encryptedData);
      let userDetails = JSON.parse(decryptedData);
      this.jwtToken = userDetails.token
    }

    // Check if the JWT token exists and is not expired
    if (encryptedData !== null && !request.url.endsWith(this.apiURL + '/auth/generate-patient-portal-token')) {
      try {
        // Decode the token
        const decodedToken: any = this.encryptDecryptService.decodeJwtToken(this.jwtToken);

        // Check if the token is expired
        if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {

          // Redirect to the login page
          this.router.navigate(['/login']); // Replace '/login' with your actual login route
          return request; // Abort the request since the token is expired
        } else {
          // Token is not expired, add it to the headers
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.jwtToken}`,
            },
          });
        }
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }

    return request;
  }

  private shouldExcludeURL(url: string): boolean {
    return this.ExcludeURLList.some((excluded) => url.startsWith(excluded));
  }

}



